function customSameAsValidator($el, required, parent) {
	// if (!required) return true;
	var field1 = $("#" + $el.attr("data-same-as")).val(),
		field2 = $el.val();
	console.log(field1, field2);
	return field1 === field2;
}

function customMinLengthValidator($el, required, parent) {
	if (!required) return true;
	var length = $el.attr("data-min-length"), //length = $("#" + $el.attr("data-min-length")).val(),
		field = $el.val();

	console.log(length, field.length);
	return field.length >= length;
}

Foundation.SmoothScroll.defaults.animationDuration = 1000;
Foundation.Abide.defaults.validators["same_as"] = customSameAsValidator;
Foundation.Abide.defaults.validators["min_length"] = customMinLengthValidator;
jQuery(document).foundation();

$(document).on("select2:open", () => {
	setTimeout(
		() =>
			document
				.querySelector(".select2-container--open .select2-search__field")
				.focus(),
		200
	);
});

$(document).on(
	"focus",
	".select2-selection.select2-selection--single",
	function (e) {
		$(this)
			.closest(".select2-container")
			.siblings("select:enabled")
			.select2("open");
	}
);

// steal focus during close - only capture once and stop propogation
$("select.select2").on("select2:closing", function (e) {
	$(e.target)
		.data("select2")
		.$selection.one("focus focusin", function (e) {
			e.stopPropagation();
		});
});

$('#vetFirstName, #vetMiddleName, #vetLastName, #vetNameSuffix, #vetDisplayName, #vetRank').on('keyup change', function() {
	var slug = clean_slug();
	$('#plaquePermalink').val(slug);
});

$('#plaquePermalink').on('keyup change', function() {
	var slug = $(this).val();
	$(this).val(clean_slug(slug));
});

jQuery(document).ready(function ($) {
	$(".input--form").dirty({
		preventLeaving: true,
	});
	
	$('#vetFirstName, #vetMiddleName, #vetLastName, #vetNameSuffix').on('keyup change', function() {
		var first = $('#vetFirstName').val().length > 0 ? $('#vetFirstName').val() + ' ' : '';
		var middle = $('#vetMiddleName').val().length > 0 ? $('#vetMiddleName').val() + ' ' : '';
		var last = $('#vetLastName').val().length > 0 ? $('#vetLastName').val() : '';
		var suffix = $('#vetNameSuffix').val().length > 0 ? ' ' + $('#vetNameSuffix').val() : '';
		
		$('#vetDisplayName').val(first + middle + last + suffix);
	});

	// $(".filter--form .submit-onchange").on("change", function () {
	// 	$(this).closest("form").submit();
	// });

	$(".button.delete, .icon.delete").on("click", function (e) {
		return confirm(
			"Are you sure you want to delete this item? This action cannot be undone!"
		);
	});
	// $(".button.cancel, .icon.cancel").on("click", function (e) {
	// 	return confirm(
	// 		"Are you sure you want to cancel and return to the previous page?"
	// 	);
	// });
	$(".for-modal").on("click", function (e) {
		e.preventDefault();
	});
	
	$('.delete-image').on('click', function(e) {
		e.preventDefault();
		
		var url = $(this).attr('href');
		var image_type = $(this).data('remove-image');
		var plaque_id = $(this).data('plaque-id');
		var data = {image: image_type, id: plaque_id};
		var parent = $(this).parent();
		var the_status = $('.delete-image-status-'+image_type);
		if(image_type == 'main'){
			var field = $('#image1');
		}
		else if(image_type == 'map'){
			var field = $('#plaque_map');
		}
		// console.log(url,image_type,plaque_id);
		
		var deleteFile = confirm('Are you sure you want to delete this image?\r\nThis action cannot be undone.');
		if( deleteFile == true ) {		
			$.ajax({
				url: url,
				type: 'post',
				dataType: 'text',
				async: true,
				data: data,
				beforeSend: function(obj) {
				},
				success: function(data, textStatus, jqXHR){
					// console.log(data);
					parent.hide();
					$('.plaque--image.'+image_type).attr('src', '/assets/images/none.jpg');
					field.val('');
					the_status.addClass('text-success').html(data);
					setTimeout( function(){ the_status.hide() }, 3000);
				},
				error: function(jqXHR, textStatus, errorThrown){
					console.log('error');
					console.log('Error: ' + jqXHR.responseText);
					parent.hide();
					the_status.addClass('text-danger').html(jqXHR.responseText).show();
					setTimeout( function(){ the_status.hide() }, 3000);
				},
				complete: function(obj,text) {
					
				},
			});
		}
	});
});

function clean_slug(input=false){
	var name = '';
	var slug = '';
	var permalink = $('#plaquePermalink').val();
	var rank = $('#vetRank option:selected').attr('data-rank-name');
	var dname = $('#vetDisplayName').val();
	var fname = $('#vetFirstName').val();
	var mname = $('#vetMiddleName').val();
	var lname = $('#vetLastName').val();
	var suffix = $('#vetNameSuffix').val();
	
	if(false === input){
		if(dname != '') {
			name = dname;
		}
		else {
			if(fname != '') {
				name += fname;
			}
			if(mname != '') {
				if(name != '') {
					name += ' ';
				}
				name += mname;
			}
			if(lname != '') {
				if(name != '') {
					name += ' ';
				}
				name += lname;
			}
			if(suffix != '') {
				if(name != '') {
					name += ' ';
				}
				name += suffix;
			}
		}
		slug = (rank != '' && rank != undefined ? rank+'-' : '')+name;
	}
	else {
		slug = input;
	}
	
	return slug
		.toLowerCase()
		.replace(/ +/g, '-')
		.replace(/[\/\[\]\(\)]/g, '-')
		.replace(/[^\w-]+/g, '');
}